<template>
  <v-expand-x-transition>
    <v-sheet
        v-if="display"
        class="admin-support-sidebar d-flex flex-column"
        :color="wsLIGHTCARD"
        elevation="2"
    >

      <v-sheet :color="wsATTENTION" class="px-2 py-2" dark>
        <div class="d-flex align-center justify-space-between">
          <div class="d-flex align-center">
            <v-btn  @click="$emit('input' , false)" icon>
              <v-icon>mdi-arrow-collapse-right</v-icon>
            </v-btn>
            <h3>{{  entity.type  }}</h3>

          </div>


          <ft-select
              :items="chatActions"
          >
            <ws-button color="#ffffff" :click-stop="false" outlined label="Завершити" icon="mdi-menu-down"></ws-button>
          </ft-select>

        </div>

      </v-sheet>

      <div class="flex-grow-1 overflow-y-auto pa-4 px-6">

        <div v-if="loading" class="fill-height d-flex align-center justify-center">
          <div style="width: 100px">
            <v-progress-linear rounded indeterminate :color="wsACCENT" />
          </div>
        </div>


        <div v-else
             class="d-flex mb-2"
             v-for="(item,i) in items" :key="i"
             :class="[{'justify-end' : item.is_user}]"
        >
          <div
              v-if="!item.is_user"
              class="fill-height mr-2"
              style="width: 24px"
          >

            <div  style="" v-if="displayAuthorCondition(item,i)">
              <img v-if="item.img" height="24px" width="24px"  :src="item.img" style="border-radius: 50%" />
              <v-sheet v-else height="24" width="24" :color="wsBACKGROUND" class="d-flex align-center justify-center" style="border-radius: 50%">
                <v-icon v-if="item.is_system" size="18" :color="wsDARKLIGHT" >mdi-robot</v-icon>

                <v-icon v-else size="18" >mdi-account-tie</v-icon>

              </v-sheet>
            </div>


          </div>

          <v-sheet
              style="border-radius: 8px; padding : 10px"
              :color="item.is_user ? wsBACKGROUND : '#ffffff'"
          >
            <h5 v-if="displayAuthorCondition(item,i) && !item.is_system"
                class="wsDARKER mb-2"
                :class="[{'text-right' : item.is_user}]"
            >
              {{ !item.is_user ? item.author : $store.state.auth.user.firstname }}
            </h5>

            <v-sheet v-if="getImageUrl(item.text)"
                     @click="openImage(getImageUrl(item.text))"
                     :color="wsBACKGROUND" style="position: relative" class="pointer" height="150px" width="100%">
              <v-img height="150px" width="220px"  :src="getImageUrl(item.text)" />
            </v-sheet>
            <h5  v-else-if="getFileUrl(item.text)"
                 @click="downloadFile(item.text)"
                 class="font-weight-regular pointer wsDARKER">
              {{ getFileName(item.text) }}
              <v-icon :color="wsDARKER">mdi-paperclip</v-icon>
            </h5>
            <h5 v-else class="font-weight-regular wsDARKER"> {{ item.text }}</h5>


            <h5 style="font-size: 12px" class="font-weight-regular wsDARKLIGHT text-right mt-2">{{ MONTH_DAY_TIME(item.date , true , true )  }}</h5>
          </v-sheet>



        </div>



      </div>

      <v-sheet class="px-4 py-2" >
        <h2>Footer is here</h2>
      </v-sheet>

      <ws-dialog
          @save="completeRequest"
          v-model="displayCompletionDialog"
          title="Завершити запит"
          save-text="Завершити"
          :disable-save="!entityData.resolution"
      >
        <ws-select
            :items="completionSelect"
            v-model="entityData.resolution"
            label="Результат"
            placeholder="З яким результатом завершено чат"
        />

      </ws-dialog>

      <ws-dialog
          @save="updateRequest"
          v-model="displayTransferDialog"
          title="Передати в інший відділ"
          save-text="Передати"
          :disable-save="!entityData.manager_group"
      >
        <ws-select
            :items="developersSelect"
            v-model="entityData.manager_group"
            label="Відділ"
            placeholder="До якого відділу належить питання"
        />

      </ws-dialog>


      <ws-dialog
          @save="updateRequest"
          v-model="displayManagerDialog"
          title="Призначити відповідального"
          save-text="Передати"
          :disable-save="!entityData.manager_id"
      >
        <ws-select
            :items="managersSelect"
            v-model="entityData.manager_id"
            label="Відділ"
            placeholder="Оберіть менеджера"
        />

      </ws-dialog>




    </v-sheet>
  </v-expand-x-transition>

</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "adminSupportSidebar",
  props : {
    value : {
      type : Boolean
    },
    entity : {
      type : Object,
      default() { return {} }
    },
    uuid : {
      type : String
    },
    managersSelect : {
      type : Array,
      default() { return [] }
    }
  },
  data() {
    return {
      items : [],
      loading : false,
      display : false,
      displayCompletionDialog : false,
      displayTransferDialog : false,
      displayManagerDialog : false,
      entityData : {},
      completionSelect : [
        { text : 'Успіх' , value : 'success'   },
        { text : 'Дубльвований запит' , value : 'duplicate' },
        { text : 'Невдача' , value : 'fail' }
      ],
      developersSelect : [
        { text : 'Розробники' , value : 'developers'   },
        { text : 'Відділ продажів' , value : 'sales'   },
        { text : 'Тех підтримка' , value : 'support'   },
      ],
      chatActions : [
        { text : "Позначити як виконано" , action : () => { this.entityData = {}; this.displayCompletionDialog = true } },
        // { text : "Передати в інший відділ" , action : () => { this.entityData = {}; this.displayTransferDialog = true } },
        { text : "Призначити відповідального" , action : () => { this.entityData = {}; this.displayManagerDialog = true } }
      ]
    }
  },
  watch : {
    uuid(value ) {
      if (value) {
        this.initChat()
      }
    },
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input' , this.display)
      }
    },
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    },
  },
  methods : {

    ...mapActions('support', [
      'ADD_ADMIN_REQUEST',
      'GET_ADMIN_REQUEST',
      'COMPLETE_REQUEST',
      'UPDATE_REQUEST',
      'ADD_COMMENT',
    ]),

    async updateRequest() {
      this.entityData.uuid = this.uuid
      let result = await  this.UPDATE_REQUEST(this.entityData)
      if ( !result ) {
        return this.ERROR(this.$store.state.ajax.error)
      }
      this.notify('Запит передано в інший відділ')
      this.$emit('update' , result)
      this.displayTransferDialog = false
      this.displayManagerDialog = false

    },

    async completeRequest() {
      this.entityData.uuid = this.uuid
      let result = await  this.COMPLETE_REQUEST(this.entityData)
      if ( !result ) {
        return this.ERROR(this.$store.state.ajax.error)
      }

      this.notify('Request Completed: ' + this.uuid , 'success' )
      this.$emit('complete' , this.entityData.resolution)
      this.displayCompletionDialog = false
      this.displayManagerDialog = false
    },

    displayAuthorCondition(item,index) {
      if ( index  < 1 ) {
        return true
      }
      if ( this.items[index - 1].author_id === item.author_id ) {
        return false
      }
      return true
    },

    downloadFile(text) {
      if ( this.getFileUrl(text)) {
        var a = document.createElement('a');
        a.href = this.getFileUrl(text);
        a.download = this.getFileName(text);
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    },
    async handleUploadFile(file) {
      if ( file.mime.includes('image')) {
        this.entityData.text = `#img:${file.url}`
      } else {
        this.entityData.text = `#file:${file.url} |#name:${file.name}`
      }

      await this.sendMessage()
      this.displayUpload = false
    },
    openImage(url) {
      this.selectedImage = url
      this.displayImageDialog = true
    },
    getImageUrl(text) {
      if (text.startsWith("#img:")) {
        let url = text.substring(5);
        return url
      }
      return false
    },
    getFileUrl(text) {
      if (text.startsWith("#file:")) {
        text = text.substring(6);
        let url = text.split(' |#name:')[0]
        return url
      }
      return false
    },
    getFileName(text) {
      if (text.startsWith("#file:")) {
        text = text.substring(6);
        let name = text.split(' |#name:')[1]
        return name
      }
      return false
    },

    async initChat(displayLoading = true) {
      if ( !this.uuid ) {
        return
      }
      if ( displayLoading)  {
        this.loading = true
      }
      let result = await this.GET_ADMIN_REQUEST(this.uuid)
      this.loading = false
      if ( !result ) {
        return this.ERROR()
      }
      this.items = result.messages
      // this.$store.state.support.chatMessagesArray = this.chatMessagesArray.filter(el => el.issue !== this.chatId )
    },

  },
  mounted() {
    if ( this.value ) {
      this.display = this.value
    }
    this.initChat()

  }
}
</script>

<style scoped>
.admin-support-sidebar {
  position: fixed;
  top: 0;
  bottom : 0;
  right: 0;
  width: 400px;
  z-index: 9;
}
</style>